<template>
    <el-dialog title="Modificare ore pontate" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject' ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >

                <el-col >
                    <el-form-item label='Proiect' >
                        <SelectProiect v-model="selectedObject.NumeProiect" :IdProiect.sync="selectedObject.IdProiect" dezactivat />                        
                    </el-form-item>
                </el-col>
                <el-col >
                    <el-form-item label='Activitate'>
                        <el-input v-model="selectedObject.NumeActivitate" class='full-width' disabled>  </el-input>                       
                    </el-form-item>
                </el-col>
                <el-col >
                    <el-form-item label='Subactivitate' >
                        <el-input v-model="selectedObject.NumeSubactivitate" class='full-width' disabled> </el-input>                        
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                   <el-form-item label='Angajat' >                                   
                        <SelectAngajat v-model="selectedObject.NumeAngajat" :IdAngajat.sync="selectedObject.IdAngajat" dezactivat />                       
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label='Data pontaj' >
                        <el-input v-model="selectedObject.DataPontaj" class='full-width' disabled> </el-input>                        
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label='Numar ore' >
                        <SelectOre ref="select-ore" v-model="selectedObject.NumarOre" />
                    </el-form-item>
                </el-col>      

            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Modificare_ore_pontate_dialog",
        extends: BasePage,
        watch:{
            selectedObject(val){
                if(val.hasOwnProperty('NumarOre'))
                {
                    console.log('numar_ore',val.NumarOre);
                }
            }
        },
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: { IdProiect: '', NumeProiect: "", IdActivitate:'', NumeActivitate:'', IdSubactivitate:'', NumeSubactivitate:'', NumeAngajat:'', IdAngajat:'', DataPontaj:'', NumarOre:'' },                                       
                rules: { NumarOre: [ { required: true, message: 'Campul este obligatoriu' } ] }
            }
        },
        methods: {
            show_me: async function( row, numar_ore, data ) {   
                console.log("am ajuns in pop-up")             
                this.showPopup                  = true;
                this.selectedObject             = row
                this.selectedObject.NumarOre    = numar_ore
                // this.selectedObject.xxx    = numar_ore
                this.selectedObject.DataPontaj  = data
                // console.log('ore',numar_ore,'obiect trimis',this.selectedObject);
                // console.log('tip',typeof(this.selectedObject.NumarOre));
                this.$nextTick(async function(){
                    // console.log(this.$refs);
                    await this.$refs['select-ore'].get_ore_alte_proiecte(this.selectedObject);
                });
                //this.$forceUpdate();
                
            },
            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("pontaj/update_ore_pontate", { object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>