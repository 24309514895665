<template>
    <el-dialog :title="'Istoric validari '" :visible.sync="showPopup" class="my-dialog-class" width="70%" :close-on-click-modal='true'>
        <el-table :data="Istoric" v-loading="loading" :row-class-name="tableRowClassName" >
            <el-table-column prop="DepartamentValidare" label="Validat">
                <template slot-scope="scope">
                    {{scope.row.NumeUtilizatorValidare}} ({{scope.row.DepartamentValidare}})
                </template>
            </el-table-column>
            <el-table-column prop="DataValidare" label="Data validare">
                <template slot-scope="scope">
                    {{scope.row.DataValidare | format-date-time}}
                </template>
            </el-table-column>
            <el-table-column prop="NumeUtilizatorAnulare" label="Anulat de"/>
            <el-table-column prop="ObservatiiAnulare" label="Observatii"/>       
            <el-table-column prop="DataAnulare" label="Data anulare">
                <template slot-scope="scope" >
                    <div v-if="scope.row.DataAnulare">
                        {{scope.row.DataAnulare | format-date-time}}
                    </div>
                </template>
            </el-table-column>
        </el-table>
         <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup = false"> Inchide  </el-button>
        </span>
    </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage"

export default {
    extends: BasePage,
    name: 'IstoricValidari',  
    data() {
        return {
            rand: {},       
            loading: true,
            showPopup: false,
            Istoric: []
        }
    },
    methods: {
        tableRowClassName({row}) {
            if (row.Anulat != 1) {
                return 'success-row';
            }
        },
        show_me: async function( row ) {
            this.showPopup      = true;
            this.rand           = row;
            this.get_info()
        },
        async get_info() {
            this.loading    = true
            var response    = await this.post("pontaj/get_istoric_pontaj", {IdProiect: this.rand.IdProiect, IdActivitate: this.rand.IdActivitate, IdSubactivitate: this.rand.IdSubactivitate, IdAngajat: this.rand.IdAngajat, An: this.rand.An, Luna: this.rand.Luna})
            this.Istoric    = response.Istoric
            this.loading    = false
        }       
    },
    mounted() {
       
    }
    
}
</script>

<style lang="less">

  .el-table .success-row {
    background: #95c07e;
  }
   
</style>